import { render, staticRenderFns } from "./v-video.vue?vue&type=template&id=ead6f16c&scoped=true&"
import script from "./v-video.vue?vue&type=script&lang=ts&"
export * from "./v-video.vue?vue&type=script&lang=ts&"
import style0 from "./v-video.vue?vue&type=style&index=0&id=ead6f16c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ead6f16c",
  null
  
)

export default component.exports