














import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class extends Vue {

  @Prop() src !: string
  @Prop() title !: string
  @Prop() dec !: string
  @Prop({
    default: false
  }) visible !: boolean
  
  @Emit('close') onClose(){
    return false
  }
}
