














import { Component, Vue, Prop } from 'vue-property-decorator';
import vVideoModule from '@/components/v-video-module.vue'
interface ModuleData {
  title: string
  dec: number
  content: string
  video:{
    src: string
    poster: string
    title: string
    dec: string
  }
}

@Component({
  components: { vVideoModule }
})
export default class extends Vue {

  @Prop() moduleData !: ModuleData
}
