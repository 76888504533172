export default {
	banner: {
		titleImg: require('../../images/home/banner_title.png'),
		video: {
			src: 'https://video.xmheigu.com/c745edf5800f42d19f73ac964f99a791/83b225e97e3145e2800c62f95f53805e-9d9503c9dc0c829d196f9532110fd6b5-hd.mp4',
			poster: 'http://www.xmheigu.com/images/ban-p5.jpg',
			title: '',
			dec: '厦门黑谷网络科技股份有限公司（以下简称“黑谷”）2018年8月诞生于美丽的鹭岛厦门，是一家以共享理念为核心，以产品研发及服务模式的创新，专业针对实体门店提供引流、增收、管理等提供一站式解决方案的互联网公司。我们的使命是“简单实体门店的生意”。黑谷秉承着"简单、实用、高效”的产品研发理念，为客户创造更高的盈利价值。发展至今，黑谷已研发出多款助力传统门店转型升级，实现盈利增收的产品软件，产品已经走向全球化，用户遍布世界各地，服务区域覆盖全国213个城市，总客户数已超5W+'
		}
	},
	moduleData: [{
			title: '共享店铺',
			dec: '把你的门店闲置资源和员工100%盘活增收',
			content: [
				'实现门店业绩快速翻倍',
				'快速稳定老客户，拓展新客户',
				'把有资源的人变成你的共享合伙人'
			],
			video: {
				src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/3287dd4e-17920ccae8f-0005-502e-0e5-a9c95.mp4',
				poster: require('../../images/public/poster/shop.jpg'),
				title: '',
				dec: ''
			}
		},
		{
			title: 'AI超级销售',
			dec: '降本增效，创造的效率是普通员工的100倍以上，成本是传统员工的10倍以下',
			content: [
				'全网24小时自动开发客户',
				'私域24小时自动盘活沉睡客户',
				'精准自动推送以及循环裂变新客'
			],
			video: {
				src: 'https://vcvod.tuchuangmf.com/customerTrans/9bca129cc843e331c69cfd1b043bab12/1b0293a9-18dee4ae171-0003-de70-c4e-daad3.mp4',
				poster: require('../../images/public/poster/hgai.jpg'),
				title: '',
				dec: ''
			}
		},
		{
			title: '整合探店网红',
			dec: '把他的客户变成你的客户',
			content: [
				'借助网红力量，轻松解决拓客难题',
				'多次探店，塑造门店品牌',
				'将一锤子买卖，转化成长期生意'
			],
			video: {
				src: 'https://video.xmheigu.com/e7c5a042b4b445ccad9cc0dd5aff7300/c316aefd5e6a45ae8cc6ba9d3fbc0fed-d001eb3c7975201e2f037a52d1d4d215-sd.mp4',
				poster: require('../../images/public/poster/whxt.png'),
				title: '',
				dec: ''
			}
		},
		{
			title: '员工合伙',
			dec: '用机制管理替代传统管理，一招点燃员工积极性',
			content: [
				'稳定优秀的员工',
				'点燃每一个员工的服务热情',
				'让每个员工都和老板一样创造业绩'
			],
			video: {
				src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/2e828f64-17920cb29da-0005-502e-0e5-a9c95.mp4',
				poster: require('../../images/public/poster/staff.jpg'),
				title: '',
				dec: '如何让员工像老板一样积极干活？如何通过机制实现员工优胜劣汰？员工合伙机制一招可以解决。一个好的管理机制不仅可以点燃员工积极性，还可以帮助门店提升服务品质，锁住稳定客源，实现持续盈利增收。'
			}
		},
		{
			title: '共享商圈',
			dec: '打造线下商圈流量共享平台',
			content: [
				'区别于传统异业联盟',
				'把门店客源快速翻倍增收',
				'为门店引流至少10倍客户'
			],
			video: {
				src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/171f2afc-17920c5f65f-0005-502e-0e5-a9c95.mp4',
				poster: require('../../images/public/poster/business.jpg'),
				title: '',
				dec: '如何让你的100个客户秒变1000个？如何让一群人帮你一起拓客？单靠传统的异业联盟模式已经行不通了。共享商圈可以帮你解决持续的拓客问题，要集结整个商圈的资源，模式加系统才是完整解决方案。'
			}
		},
		{
			title: '客源引爆系统',
			dec: '1个月新增1000个新客户',
			content: [
				'系统化引爆门店客流',
				'视频营销，客户购买欲倍增',
				'解决员工流失，激发员工斗志'
			],
			video: {
				src: 'https://video.xmheigu.com/0d8213d8ff6a49c29726cd6b43c57a1e/c4fc947643264467b4dee3c8ae1114ea-017f9a27613cab6cd8d912c990a08204-ld.mp4',
				poster: require('../../images/public/poster/marketing.jpg'),
				title: '',
				dec: ''
			}
		},
	]
}
