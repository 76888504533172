








import { Component, Prop, Vue } from "vue-property-decorator";
import vVideo from './v-video.vue'

@Component({
  components: { vVideo }
})
export default class extends Vue {

  private videoVisible = false

  @Prop() data !: {src: string, poster:string, title: string, dec:string}
  
}
