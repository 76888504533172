




































import {
	Component,
	Prop,
	Vue
} from "vue-property-decorator";
import vVideo from '@/components/v-video.vue'
import HomeModule from "./components/home-module.vue"
import homeData from '@/assets/data/pages2/newHome'
import vFreeApply from '@/components/v-free-apply.vue';

@Component({
	components: {
		vVideo,
		HomeModule,
		vFreeApply
	}
})
export default class extends Vue {

	private vVideoVisible = false
	private bannerData = homeData.banner
	private moduleData = homeData.moduleData

}
